import AlertPopup from '../components/AlertPopup/AlertPopup.component';
import CookiePopup from "../components/CookiePopup/CookiePopup.component";
import InputField from '../components/InputField/InputField.component';
import DefaultButton from "../components/DefaultButton/DefaultButton.component";
import DefaultCard from '../components/DefaultCard/DefaultCard.component';
import ResultBox from '../components/ResultBox/ResultBox.component';
import homePageGraphic from '../assets/home_page-graphic.svg';
import React from "react";
import { Link, useLoaderData } from 'react-router-dom';
import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from "react-redux";
import {CloseIcon} from '../components/CloseIcon/CloseIcon.component';
import {baseRentData, baseExpensesData} from '../data/defaults';
import {colors} from "../mixins/colorScheme";
import {isMobileDevice} from "../mixins/deviceChecker";
import {updateCurrency} from "../helpers/selectCurrency.slice";
import dayjs from 'dayjs';
import bgGraphic from '../assets/bg-vector-graphic.svg'
import updateMetaDescription from '../mixins/updateMetaDescription';
import {Adsense} from '@ctrl/react-adsense';

import all_posts from '../data/blog/all-posts.json';

export const homePageLoader = () => {
    const articles = all_posts.data || [];
    const sortedArticles = articles.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));

    return { articles: sortedArticles.slice(0, 3) };
};

export default function Home() {
    const [rentInputCount, setRentInputCount] = useState(2);
    const [expensesInputCount, setExpensesInputCount] = useState(2);
    const [rentData, setRentData] = useState(baseRentData);
    const [expensesData, setExpensesData] = useState(baseExpensesData);
    const [showPopup, setShowPopup] = useState(false)
    const [showCookieBanner, setShowCookieBanner] = useState(true)
    const currency = useSelector(state => state.currency.value)
    const dispatch = useDispatch()
    const homePageArticles = useLoaderData()?.articles;

    const showResults = useMemo(() => {
        let rentDataExists = rentData.some((obj) => obj.value);
        let expensesDataExists = expensesData.some((obj) => obj.value);

        return rentDataExists && expensesDataExists;
    }, [rentData, expensesData])

    updateMetaDescription('The perfect rent splitting calculator! Fair Split Calculator offers a quick, accurate, and easy-to-use solution to equitably calculate and split rent and other bills between any number of people.')

    function removeDataEntry(item, dataType, setDataType, countType, setCountType){
        setDataType(dataType.filter(el => el.id !== item.id))
        setCountType(countType - 1)
    }

    function newDataEntry(dataType, setDataType, countType, setCountType) {
        setDataType([...dataType, {id: countType, label: '', value: ''}])
        setCountType(countType + 1)
    }

    function handleDataChange(item, index, type) {
        let dataType, newData, setDataType;

        if (type === 'expenses') {
            dataType = expensesData
            setDataType = setExpensesData
        }
        if (type === 'rent') {
            dataType = rentData
            setDataType = setRentData
        }
        newData = [...dataType]

        if (item.target.className.includes('input')) {
            newData[index].value = item.target.value;
        }
        if (item.target.className === 'label') {
            newData[index].label = item.target.value;
        }
        setDataType(newData)
    }

    function handleResultCalculation(clickEvent) {
        let totalExpenses = 0;
        let totalIncome = 0;
        expensesData.forEach(el => {
            let value = el.value ? Number(el.value) : 0 // catch for empty inputs
            totalExpenses = totalExpenses + value
        })

        rentData.forEach(el => {
            let value = el.value ? Number(el.value) : 0 // catch for empty inputs
            totalIncome = totalIncome + value
        })

        let updatedRentData = [...rentData]
        updatedRentData.forEach(el => {
            let x = (el.value/totalIncome)
            if (x) {
                el.percentage = (x * 100).toFixed(1) + '%';
            } else {
                el.percentage = '0.0%';
            }
            if (x) {
                el.rental_share = (x * totalExpenses).toFixed(2);
            } else {
                el.rental_share = '0.00';
            }
        })

        if (!totalIncome && clickEvent) {
            newAlert('Enter an income to start calculating!', 'warning')
        } else if (!totalExpenses && clickEvent) {
            newAlert('Enter an expense to start calculating!', 'warning')
        } else {
            setRentData(updatedRentData)
        }
    }

    function handleShowPopup(shouldShow) {
        if (showResults && shouldShow) {
            setShowPopup(true)
        } else {
            setShowPopup(false)
        }
    }

    const [colourSchemeCount] = useState(0);
    let colourScheme = {
        '--primary': colors[colourSchemeCount].primary,
        '--secondary': colors[colourSchemeCount].secondary,
        '--background': colors[colourSchemeCount].background
    }

    const [alertStack, setAlertStack] = useState([])
    function newAlert(message, type) {
        let newAlertStack = [...alertStack]
            newAlertStack.push({message: message, shouldShow: true, type: type})
        setAlertStack(newAlertStack)
    }

    function removeAlert(index) {
        let removeEl = alertStack.filter(el => el['shouldShow'] !== true)
        setAlertStack(removeEl)
    }

    function shareResult() {
        let encodedData = {
            rentData: rentData,
            expensesData: expensesData,
            currency: currency
        }
        let dataString = JSON.stringify(encodedData)
        encodedData = btoa(encodeURIComponent(dataString)) // encode user data
        let shareUrl = window.location.origin + '?sharedData=' + encodedData

        if (navigator.share) {
            navigator
                .share({
                    title: "FairSplitCalc",
                    text: "Check this out:",
                    url: shareUrl,
                })
                .then(() => console.log("Successful share"))
                .catch((error) => console.log("Error sharing", error));
        } else {
            console.error("Browser doesn't support Web Share API");
            navigator.clipboard.writeText(shareUrl).then(
                () => {
                    newAlert('URL copied to clipboard!', 'success')
                }
            );
        }
    }

    function populateSharedData() {
        let urlParameters = window.location.search
        let sharedData = urlParameters.replace('?sharedData=', '')
        sharedData = JSON.parse(decodeURIComponent(atob(sharedData)))
        setRentData(sharedData['rentData'])
        setExpensesData(sharedData['expensesData'])
        dispatch(updateCurrency(sharedData['currency']))
    }

    useEffect(() => {
        if (!isMobileDevice()) {
            setShowPopup(true)
        }

        if (!!window.localStorage.getItem('cookieConsent')) {
            setShowCookieBanner(false)
        }

        if (window.location.search.includes('sharedData')) {
            populateSharedData()
            if (isMobileDevice()) {
                setShowPopup(true)
            }
            // clear url params without refreshing page
            const url = new URL(window.location);
            url.search = '';
            window.history.pushState({}, '', url);
        }
    }, [])

    return (
        <div className="App" style={colourScheme}>
            {alertStack.map((item, index) => (
                item['shouldShow']
                    ? (<AlertPopup message={item.message} key={index} handleUnmount={() => removeAlert(index)} type={item.type} />)
                    : null
            ))}

            { showCookieBanner &&
                <CookiePopup acceptCookie={() => setShowCookieBanner(false)}/>
            }

            <div className='flex m-auto lg:grid grid-cols-[1fr_800px_1fr] max-w-[1400px]'>
                <div className="hidden lg:block w-full p-5 text-right h-full">
                    <Adsense
                        data-ad-client="ca-pub-9750567100834191"
                        slot="2679096007"
                        style={{ display: 'block', position: 'sticky', top: '90px' }}
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>

                <div className='w-full max-w-[800px] m-auto'>
                    <div className="app-container m-auto mb-6 p-5 lg:p-0">
                        <section className="header-section lg:pt-5">
                            <h1 className='text-3xl md:text-4xl text-center font-bold mb-4'>Equitable Expenses Calculator</h1>
                            <h1 className='mb-2'>Fair Split Expenses Calculator is a free tool for equitably calculating shared expenses.</h1>
                        </section>

                        <section className="main-section">
                            <DefaultCard
                                title='Monthly Income'
                                infoText='Add the number of people you want to split expenses with, then enter the income for each person in the following input fields. You can also edit the input label by tapping it.'
                            >
                                {rentData.map((item, index) =>
                                    <div className={'card-row'} key={'item_' + index}>
                                        <InputField
                                            placeholder={'100'}
                                            showLabel
                                            labelPlaceholder={`Person ${index + 1}`}
                                            label={item.label}
                                            value={item.value}
                                            onChange={(e) => {
                                                handleDataChange(e, index, 'rent');
                                                handleResultCalculation();
                                            }}
                                        />

                                        {(index > 1 && index + 1 === rentData.length) &&
                                            <CloseIcon
                                                onClick={() => removeDataEntry(item, rentData, setRentData,  rentInputCount, setRentInputCount)}
                                                disabled={rentInputCount <= 2}
                                            />
                                        }
                                    </div>
                                )}

                                <DefaultButton
                                    onClick={() => newDataEntry(rentData, setRentData, rentInputCount, setRentInputCount)}
                                >
                                    Add Person
                                </DefaultButton>
                            </DefaultCard>

                            <DefaultCard
                                title='Monthly Expenses'
                                infoText='Enter all your shared expenses such as rent, utility bills or monthly subscriptions in the following input fields. You can also edit the input label by tapping it.'
                            >
                                {expensesData.map((item, index) =>
                                    <div className={'card-row'} key={'item_' + index}>
                                        <InputField
                                            placeholder={'100'}
                                            showLabel
                                            labelPlaceholder={`Expense ${index + 1}`}
                                            label={item.label}
                                            value={item.value}
                                            onChange={(e) => {
                                                handleDataChange(e, index, 'expenses');
                                                handleResultCalculation();
                                            }}
                                        />

                                        {(index > 0 && index + 1 === expensesData.length) &&
                                            <CloseIcon
                                                onClick={() => removeDataEntry(item, expensesData, setExpensesData, expensesInputCount, setExpensesInputCount)}
                                                disabled={rentInputCount <= 2}
                                            />
                                        }
                                    </div>
                                )}


                                <DefaultButton
                                    onClick={() => newDataEntry(expensesData, setExpensesData, expensesInputCount, setExpensesInputCount)}
                                >
                                    Add Expense
                                </DefaultButton>
                            </DefaultCard>
                        </section>

                        { isMobileDevice() &&
                            <DefaultButton
                                onClick={() => {
                                    handleResultCalculation(true);
                                    handleShowPopup(true);
                                }}
                            >
                                Calculate
                            </DefaultButton>
                        }

                        { showPopup &&
                            <section className="results-section">
                                <div
                                    className={`results-box ${isMobileDevice() ? "__popup-overlay" : ""}`}
                                >
                                    <div className="container">
                                        <div className={'close-button'} onClick={() => handleShowPopup(false)}>×</div>

                                        <DefaultCard>
                                            <p className={'title'}>Incomes</p>
                                            <div className={'card-row'}>
                                                {rentData.map((item, index) =>
                                                    <InputField
                                                        placeholder={'100'}
                                                        showLabel
                                                        labelPlaceholder={`Person ${index + 1}`}
                                                        label={item.label}
                                                        value={item.value}
                                                        onChange={(e) => {
                                                            handleDataChange(e, index, 'rent');
                                                            handleResultCalculation();
                                                        }}
                                                        key={index}
                                                    />
                                                )}
                                            </div>

                                            <p className={'title'}>Expenses</p>
                                            <div className={'card-row'}>
                                                {expensesData.map((item, index) =>
                                                    <InputField
                                                        showLabel
                                                        labelPlaceholder={`Expense ${index + 1}`}
                                                        style={expensesData.length <= 1 ? {'flexBasis': '100%'} : {'': ''}}
                                                        placeholder={'100'}
                                                        label={item.label}
                                                        value={item.value}
                                                        onChange={(e) => {
                                                            handleDataChange(e, index, 'expenses');
                                                            handleResultCalculation();
                                                        }}
                                                        key={index}
                                                    />
                                                )}
                                            </div>

                                            <p className={'title'}>Equitable Split</p>
                                            <div className="card-row"
                                                style={rentData.length > 2 ? {gridTemplateColumns: 'auto'} : {gridTemplateColumns: '1fr 1fr'}}
                                            >
                                                {rentData.map((item, index) =>
                                                    <ResultBox
                                                        result={item}
                                                        index={index}
                                                        key={index}
                                                    />
                                                )}
                                            </div>

                                            <DefaultButton onClick={() => shareResult()}>Share Result</DefaultButton>
                                        </DefaultCard>
                                    </div>
                                    <div className="background" onClick={() => handleShowPopup(false)}></div>
                                </div>
                            </section>
                        }
                    </div>

                    <section className="w-full m-auto block mb-6 text-center">
                        <Adsense
                            data-ad-client="ca-pub-9750567100834191"
                            slot="2679096007"
                            style={{ display: 'block' }}
                            data-ad-format="auto"
                            data-full-width-responsive="true"
                        />
                    </section>

                    <section className="w-full m-auto p-5 lg:p-0 max-w-[800px] mb-12 flex flex-col">
                        <h2 className='text-center text-3xl md:text-4xl font-bold mb-6'>Why Split Equitably?</h2>
                    
                        <div className='flex flex-col md:flex-row items-center gap-10'>
                            <div className='min-w-[280px] max-w-[280px]'>
                                <img src={homePageGraphic} alt="Woman sitting working on laptop"/>
                            </div>
                            
                            <div className='flex flex-col gap-y-2 max-w-[600px]'>
                                <p>Splitting expenses equitably, rather than equally, acknowledges that fairness in financial matters goes beyond simple division.</p>
                                <p>By factoring in individual incomes and capabilities, equitable splitting ensures that each person contributes fairly, making it a more considerate and just approach to sharing expenses.</p>
                                <p>The Fair Split Calculator specializes in rent splitting and managing shared expenses, providing a reliable and user-friendly tool for such financial tasks.</p>
                            </div>
                        </div>

                        <Link className="m-auto" to={'/about'}>
                            <button className="btn btn-primary text-white rounded-full btn-sm h-auto py-3 shadow btn-wide mt-6 normal-case">More About Us</button>
                        </Link>
                    </section>
                </div>

                <div className="hidden lg:block w-full p-5 h-full">
                    <Adsense
                        data-ad-client="ca-pub-9750567100834191"
                        slot="2679096007"
                        style={{ display: 'block', position: 'sticky', top: '90px' }}
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>
            </div>


            {homePageArticles && <section className='w-full pb-10 pt-12 bg-repeat-x bg-cover md:bg-contain mb-12' style={{ backgroundImage: `url(${bgGraphic})` }}>
                <div className="w-full m-auto p-5 max-w-[800px] mb-12 flex flex-col" >
                    <div className='flex justify-between items-baseline mb-6'>
                        <h2 className='text-center text-3xl md:text-4xl font-bold'>Blog</h2>
                        <Link to={'/blog'} className='font-bold'>Read More →</Link>
                    </div>
                    <div className='flex flex-col md:grid md:grid-cols-3 items-center gap-5 items-stretch'>
                        { homePageArticles?.map((article, index) => (
                            <Link to={'/blog/post' + article.slug} className='flex flex-col bg-white px-5 pt-3 pb-5 rounded-xl shadow-md gap-y-2 h-auto hover:scale-105 transition-all cursor-pointer' key={index}>
                                <p className='font-bold text-primary uppercase text-sm'>{article.category.label}</p>
                                <h4 className='text-lg font-bold h-full'>{article.title}</h4>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>}

            <section className='w-full m-auto p-5 max-w-[800px]'>
                <h2 className='text-3xl md:text-4xl text-center font-bold mb-6'>Frequently Asked Questions</h2>
                <p className='max-w-[400px] text-center mx-auto mb-8'>Is there something you need help with, or have any questions about the tool?</p>
                <div className="join join-vertical w-full bg-[#EEEEF8] mb-12">
                    <div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4" /> 
                        <div className="collapse-title font-bold">Why is equitable splitting better than equal sharing?</div>
                        <div className="collapse-content"> 
                            <p>Equitable splitting ensures that each person contributes based on their financial capacity, promoting fairness and preventing financial strain on lower-income individuals.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4"/>
                        <div className="collapse-title font-bold">How does the calculation work?</div>
                        <div className="collapse-content"> 
                            <p>To make an equitable calculation, you divide the total sum of earnings by each persons income, then apply that value to the total value of expenses.</p>
                            <p>For example, if person A earns {currency}1,800, and person B earns {currency}1,200, their total combined earnings would be {currency}3,000. You then take each person's earnings and divide it by this total to find their proportional percentage value. For instance, Person A's share would be calculated as {currency}1,800 / {currency}3,000, which equals 0.6 or 60%, and person B's share would be 40% ({currency}1,200 / {currency}3,000 = 0.4 or 40%).</p>
                            <p>Now if person A and B's combined expenses were {currency}400, then person A would pay {currency}240 and person B would pay {currency}160.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-plus join-item border-0 border-b border-b-[#F3F3FF]">
                        <input type="radio" name="my-accordion-4" /> 
                        <div className="collapse-title font-bold">Can this application handle complex financial situations?</div>
                        <div className="collapse-content"> 
                            <p>Yes, it's designed to handle various scenarios, such as shared rent, utilities, or even group vacations, making it versatile for different needs.</p>
                        </div>
                    </div>
                    <div className="collapse collapse-plus join-item border-0">
                        <input type="radio" name="my-accordion-4" /> 
                        <div className="collapse-title font-bold">Is my financial information secure when using this application?</div>
                        <div className="collapse-content"> 
                            <p>Absolutely. None of the values entered in the form and or subsequently shared, is stored or collected.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
