import { Link, useLoaderData } from "react-router-dom";
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import { CTAButton } from "../components/CTAButton/CTAButton";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import updateMetaDescription from "../mixins/updateMetaDescription";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {Adsense} from '@ctrl/react-adsense';

import all_posts from '../data/blog/all-posts.json';
import { use } from "react";

export const blogPostLoader = async (params) => {
    const article = all_posts.data.find(post => post.slug === '/' + params);

    return { data: article};
}

export default function BlogPage() {
    const data = useLoaderData().data
    const date = dayjs(data?.publishedAt).format("DD MMMM, YYYY")
    updateMetaDescription(data.article_content)

    useEffect(() => {
        const headerEls = document.querySelector('.blog-content').getElementsByTagName("h3"); // Get all <h3> elements within the blog content
        if (!headerEls || headerEls.length === 0) return; // If no <h3> elements are found, exit the function
        let headerIndex = 1;
    
        let refChild = headerEls[headerIndex];
    
        // Check if an element with the id 'ad-container' is present in the document
        if (document.querySelector('.blog-content #ad-container') === null) {
            for (headerIndex; headerIndex < headerEls.length; headerIndex++) {
                refChild = headerEls[headerIndex];
                const checkPrevEl = refChild.previousElementSibling.querySelector('img'); // Check if the previous sibling of the header contains an <img> tag
                if (checkPrevEl) {
                    continue;
                } else {
                    break;
                }
            }
    
            // Create a new <div> element with the id 'ad-container'
            const adContainer = document.createElement("div");
            adContainer.id = "ad-container";
    
            // Insert the ad container before the reference header element
            refChild && refChild.parentNode.insertBefore(adContainer, refChild);
    
            // Retrieve the ad container DOM node and render Adsense component within it
            const domNode = document.getElementById('ad-container');
            const root = createRoot(domNode);
            root.render(
                <Adsense
                    data-ad-client="ca-pub-9750567100834191"
                    slot="5326747555"
                    style={{ display: 'block', textAlign: 'center' }}
                    data-ad-layout="in-article"
                    data-ad-format="fluid"
                />
            );
        }
    },[]);
    

    return (
        <div className="m-auto max-w-[480px] md:max-w-[800px] p-5">
            <HelmetProvider>
                <Helmet>
                    <title>{data.title} – Fair Split Calculator</title>
                </Helmet>
            </HelmetProvider>
                    
			<section className="mb-4 flex flex-col gap-y-2 md:gap-y-4 max-w-[600px] m-auto">
                <Link to={`/blog/${data.category.url}`}>
                    <h3 className="text-xl font-bold uppercase text-primary tracking-tight hover:underline">{data.category.label}</h3>
                </Link>
                {/* <p>{date}</p> */}
				<h1 className="text-4xl md:text-5xl leading-snug  tracking-tight md:leading-[normal] font-bold mb-2">{data.title}</h1>
                <div className="aspect-[4/3] relative mb-4">
                    <img src={data.cover_image.url} className="absolute object-cover w-full h-full" alt={data.title} />
                </div>
                <ReactMarkdown 
                    className="blog-content font-light flex flex-col prose prose-ol:mt-0 [&_a]:text-primary [&_a]:font-bold [&_a]:no-underline [&_a:hover]:underline [&_img]:mb-0 [&_h3]:my-3"
                >
                    {data.article_content}
                </ReactMarkdown>
                <CTAButton/>
                <Link to={'/blog'} className="font-bold text-primary mt-4">← Return To All Blog Posts</Link>
                
                <Adsense
                    data-ad-client="ca-pub-9750567100834191"
                    slot="6009273839"
                    style={{ display: 'block' }}
                    data-ad-layout="in-article"
                    data-ad-format="autorelaxed"
                />
			</section>
        </div>
    )
}
